<template>
  <LMarker
    v-bind="options"
    @click="(e) => $emit('click', { position: e.latlng })"
    @drag="(e) => $emit('drag', e.latlng)"
    @dragend="(e) => $emit('dragend', e.target.getLatLng())"
    @ready="handleReady"
  >
    <LPopup
      v-if="$slots.infowindow"
      ref="popup"
      :options="{ closeButton: false, offset: [0, -28], keepInView: true }"
    >
      <a class="leaflet-popup-close-button" @click="handleClose">×</a>
      <slot name="infowindow" />
    </LPopup>
    <l-icon v-if="$slots.default" :icon-anchor="anchor" :key="`LM-I-${key}`">
      <slot />
    </l-icon>
  </LMarker>
</template>

<script>
import { LMarker, LIcon, LPopup } from 'vue2-leaflet';

export default {
  components: {
    LIcon,
    LMarker,
    LPopup
  },
  data() {
    return {
      key: 1,
      isPopupOpen: false
    };
  },
  beforeUpdate() {
    this.key++;
    console.log('::📍 ⏱️');
  },
  computed: {
    options() {
      const props = this.$props;
      return {
        ...props,
        latLng: props.position,
        zIndexOffset: props.zIndex
      };
    }
  },
  methods: {
    handleReady(marker) {
      this.$nextTick(() => {
        if (this.autoOpenInfowindow) marker.openPopup();
      });
    },
    handleClose() {
      this.$emit('popupClose');
      this.$refs.popup.mapObject.close();
    }
  },
  props: {
    anchor: { type: Array, default: () => [16, 37] },
    autoOpenInfowindow: { type: Boolean, default: false },
    draggable: { type: Boolean, default: false },
    position: { type: Object, default: () => [] },
    zIndex: { type: Number, default: 1 }
  }
};
</script>
